@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.font-inter {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.font-tinos {
  font-family: "Tinos", serif;
  font-weight: 400;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: '';
}

::-webkit-scrollbar-thumb {
  background: '';
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: '';
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 0.8s linear infinite;
}

.loader {
  width: 64px;
  height: 48px;
  position: relative;
  animation: split 1s ease-in infinite alternate;
}

.loader::before,
.loader::after {
  content: '';
  position: absolute;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  left: 0;
  top: 0;
  transform: translateX(-10px);
  background: #FF9700;
  opacity: 0.75;
  backdrop-filter: blur(20px);
}

.loader::after {
  left: auto;
  right: 0;
  background: #0A1929;
  transform: translateX(10px);
}

@keyframes split {

  0%,
  25% {
    width: 64px
  }

  100% {
    width: 148px
  }
}

.img-bg {
  width: 100%;
  height: 650px;
  background-image:
    linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)),
    url('./assets/hero.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.dashed {
  border-style: dashed;
}

.testimonial-slider {
  max-width: 1200px;
  margin: 0 auto;
}

.custom-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 0 15px;
}

.custom-arrow svg {
  color: white;
}

.next-arrow,
.prev-arrow {
  position: relative;
}

.slick-slide {
  padding: 0 15px;
}

.bg-image {
  background-image: url('./assets/miles_logo.svg');
}

@media print {
  body {
    margin: 0;
    padding: 0;
  }

  .print-content {
    width: 100%;
    margin: 0 auto;
    page-break-inside: avoid;
  }

  .page-break {
    page-break-before: always;
  }

  .watermark {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    font-size: 48px;
    color: rgba(0, 0, 0, 0.1);
    pointer-events: none;
    user-select: none;
  }
}